import React from 'react';
import Opshu       from 'components/00_atoms/opshu/opshu';
import Header      from 'components/01_molecules/header/header';
import Footer      from 'components/01_molecules/footer/footer';
import AnimatedRoutes from 'components/AnimatedRoutes';
import './App.scss';

const App = () => {

  return (
    <div className={'app-root'}>
      <Opshu/>
      <Header/>
      <div className='contents'>
        <AnimatedRoutes />
      </div>
      <Footer/>
    </div>
  )
}

export default React.memo(App);
