import React from 'react';

const I = () => {
  return (
    <svg id='svg_id_i' width="1024" height="1024" viewBox="0 0 1024 1024">
      <path d="M827.085 285.192c-102.488-106.989-200.072-135.111-345.565-126.208-104.694 6.422-211.546 32.744-301.039 88.55-67.622 42.183-136.618 85.971-159.126 166.98-7.454 26.76-10.543 51.184-6.424 78.673 2.942 19.656 9.856 39.069 17.899 57.363 11.23 25.641 26.97 49.919 51.538 64.709 22.9 13.769 50.067 18.294 76.155 21.7 37.121 4.865 74.389 7.979 111.314 14.158 3.776 0.632 11.769 0.487 14.858 2.627 31.139 21.456 97.976 54.735 135.294 61.839 13.485-2.384 26.97-4.719 40.505-7.103 25.548-4.476 42.908-11.239 60.267 12.309 11.622 15.764 15.545 37.609 21.822 55.173 6.571 18.294 13.093 36.539 19.664 54.833 0.687 1.995 13.093 35.615 12.799 35.76 72.183-30.019 152.113-36.831 223.512-69.526 18.242-8.368 35.846-18.342 51.391-30.992 7.356-5.984 14.074-12.504 20.35-19.607 7.061-7.979 12.75-18.78 22.9-23.013 14.221-5.887 30.697-3.795 45.114-9.585 11.622-4.719 20.694-13.38 27.853-23.402 7.895-11.044 13.632-23.5 19.566-35.663 3.285-6.714 6.522-13.477 10.053-20.094 2.648-4.963 10.347-13.282 11.131-18.634 18.879-128.348-100.575-246.042-181.83-330.845zM853.222 559.064l-79.833 8.222-269.606-169.607-224.443 92.637 78.312 28.414-140.099 28.414-49.724-44.859 6.032-53.811 329.873-133.019 349.439 188.29v55.319z"></path>
    </svg>
  )
}
export default I
