import React, { useEffect, useState, useRef, ReactNode, PropsWithChildren } from "react";
import { RGB } from "static/ts/interfaces";

interface Props extends PropsWithChildren<any>{
  className      : string,
  children       : ReactNode,  
}

const rgbB : RGB = {
  red : 0,
  blue : 0,
  green : 0,
}

const FloatCard = ({
  className,
  children,  
} : Props) => {
  const wpRef = useRef<HTMLDivElement>(null!)
  const inRef = useRef<HTMLDivElement>(null!)
  const [gap, setGap] = useState(0)

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const scrollCenter = scrollTop + clientHeight / 2
    const scSrt = wpRef.current.offsetTop
    const scEnd = wpRef.current.offsetHeight + scSrt
    const scCnt = ( scEnd + scSrt ) / 2

    let cntGap = scCnt - scrollCenter

    if (cntGap > 1000) {
      cntGap = 100
    }

    setGap(cntGap * cntGap * cntGap / 100000)
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div 
      className={className} 
      ref={wpRef}
      style={{
        position: 'relative',
        background: 'inherit',
        height: inRef.current?.offsetHeight,
        width: inRef.current?.offsetWidth,
      }}
    >
      <div 
        className="inner" 
        ref={inRef}
        style={{
          position: 'absolute',
          background: 'inherit',
          top: gap, 
        }}
      >
        {children}
      </div>
    </div>
  );
};


export default FloatCard;
