import MotionDiv from "components/00_atoms/MotionDIv/MotionDIv"

export const WPython = () => {
  return (
    <MotionDiv className="wPython-wrapper">
      Python page!!!
    </MotionDiv>
  )
}

export const WC = () => {
  return (
    <MotionDiv className="wC-wrapper">
      C page!!!
    </MotionDiv>
  )
}

export const WJava = () => {
  return (
    <MotionDiv className="wJava-wrapper">
      java page!!!
    </MotionDiv>
  )
}

export const WHtml = () => {
  return (
    <MotionDiv className="wHtml-wrapper">
      html page!!!
    </MotionDiv>
  )
}

export const WBackend = () => {
  return (
    <MotionDiv className="wBackend-wrapper">
      backend page!!!
    </MotionDiv>
  )
}