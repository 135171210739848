import React, { ReactNode } from 'react';
import { motion, MotionValue } from 'framer-motion';

const MotionDiv = (props : {
  className : string,
  children : ReactNode,
}) => {
  return (
    <motion.div 
      className={props.className}
      initial={{
        opacity:0, 
        y:30, 
        zIndex:1,
      }}
      animate={{
        opacity:1, 
        y:0, 
        zIndex:1
      }}
      exit={{
        opacity:0, 
        y:30, 
        zIndex:0,
      }}
    >
      {props.children}
    </motion.div>
  )
}

export default MotionDiv
