import React from "react"
import { useNavigate, Link } from "react-router-dom"

export const ErrorPage = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1>NOT FOUND PAGE</h1>
      <Link to={'/'}>MOVE TO TOP</Link>
    </div>
  )
}