export const sizes = {
  header: {
    height: 80,
  }
} as const

export const LangList = [
  'Python' ,
  'C'      ,
  'java'   ,
  'html'   ,
  'backend',
] as const
