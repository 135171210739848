import React, { useEffect, useState } from 'react';
import ScrollText from 'components/00_atoms/scrollText';
import './home.scss'

const Home = () => {
  return (
    <div className='home-wrapper'>
      <div className='home-contents'>
        <div className='head'>
          <h1 className='gradient'>小学校高学年</h1>
        </div>
        <div className='mid'>
          <ScrollText
            className={'part1'}
          >
            <p className='gradient'>
              小学校の中でも最高学年になり半年程過ぎた頃。<br />
              <br />
              革命児のヤンチャぶりにも慣れてきた。<br />
              <br />
              全てのエピソードを書き記したいぐらいだが、noteには少し余白が足りない。<br />
              <br />
              終礼（帰宅する前）で当月のテーマソングを決め、クラス全員で歌う。<br />
              <br />
              クラスの問題児にブチギレ。教室中を引きずり回す。<br />
              <br />
              音楽会では衣装・バンド編成を考案。自クラスに実行させる<br />
              <br />
              、、、等々。今思い返してもまさに革命児。<br />
              当時はされるがまま受け入れてはいたが、一般的にはいかがなものか。<br />
              まあ、一般とは何だって話だが。<br />
            </p>
          </ScrollText>
          <ScrollText
            className={'part2'}
          >
          <p className='gradient'>
            革命児による刺激的な日常を送っていたある日。<br />
            <br />
            よくある「給食員の方に感謝のメッセージを書こう」<br />
            <br />
            のイベントがやってきた。<br />
          </p>
          </ScrollText>
          <ScrollText
            className={'part3'}
          >
            <p className='gradient'>
              四方形の教室に詰まった机。<br />
              <br />
              最前列のメンバーに5枚ずつ配られるメッセージカード。<br />
              <br />
              後ろに紙を回しながらニヤつく友の顔を見ながら何を書こうかと考える。<br />
            </p>
          </ScrollText>

          <ScrollText
            className={'part4'}
          >
            <p className='gradient'>
            「いつもおいしい給食をありがとうございます。<br />
            　　　　　　　　　これからも美味しい給食を作ってください。」<br />

            </p>
          </ScrollText>

          <ScrollText
            className={'part5'}
          >
            <p className='gradient'>
              、、、こんなもだろう。これはよくあるイベント。<br />
              <br />
              いつもありがとうございます。<br />
              <br />
              これからもいつも通り美味しい給食をお願いいたします。<br />

            </p>
          </ScrollText>

          <ScrollText
            className={'part6'}
          >
            <p className='gradient'>
              これで今回のイベントミッションは完了だ。<br />

            </p>
          </ScrollText>

          <ScrollText
            className={'part7'}
          >
            <p className='gradient'>
              全員が想いを書き終え、メッセージカードを集める革命児。<br />
              <br />
              トランプのように束ねられたカードたちを1枚ずつめくり、目を通していく。<br />
            </p>
          </ScrollText>

          <ScrollText
            className={'part8'}
          >
            <p className='gradient'>
              すると、そのトランプから選ぶようにカードを引き抜いていく。<br />
              <br />
              これから手品でもするのだろうか。<br />
              <br />
              タネを仕込んでいるとしたらバレバレである。<br />

            </p>
          </ScrollText>

          <ScrollText
            className={'part9'}
          >
            <p className='gradient'>
              次の瞬間、残ったカードの山を教室の中心に向かって勢いよく撒き散らした。<br />
            </p>
          </ScrollText>
          <ScrollText
            className={'part10'}
          >
            <p className='gradient'>
              「「「こんなのメッセージじゃねえ」」」<br />
            </p>
          </ScrollText>
        </div>
      </div>
    </div>

  )
}

export default Home
