import React, { useEffect, useState } from 'react';
import { LangList } from 'static/ts/constants';
import MotionDiv    from 'components/00_atoms/MotionDIv/MotionDIv';
import ScrollText   from 'components/00_atoms/scrollText';
import Popup        from 'components/00_atoms/popup/popup';
import './works.scss'
import { useNavigate } from 'react-router-dom';


interface isLangList {
  [key: string]: boolean
}

const Works = () => {
  const navigate = useNavigate()
  const DEF_RGB = {
    red: 150,
    blue: 150,
    green :150, 
  }


  const popDtHandler = (e : React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    navigate(`${id}`)
  }

  const renderScrollItems = () => {
    return(
      LangList.map((v, i) => {
        return(
          <ScrollText
            className={v}
            fontSizeRatio={2}
            sRGB={DEF_RGB}
            key={v+i}
          ><h2 onClick={popDtHandler} id={v}>{v}</h2></ScrollText>
        )
      })
    )
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [])

  return (
    <MotionDiv className='works-wrapper'>
      <div className='head'>
        <h1>LANGUAGES</h1>
      </div>
      <>
      {renderScrollItems()}
      </>
    </MotionDiv>
  )
}

export default Works