import React from 'react';
import I from 'static/img/i';
import './opshu.scss'

const Opshu = () => {
  return (
    <div className='opshu-wrapper'>
      <I/>
    </div>
  )
}

export default Opshu
