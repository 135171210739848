import React from 'react';

import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { ErrorPage } from 'static/ts/error';
import { LangList }  from 'static/ts/constants';
import { Layout } from 'components/00_atoms/Layout';
import FloatUpTest from 'components/02_organisms/floatUpTest/floatUpTest';
import Home        from 'components/03_pages/home/home';
import About       from 'components/03_pages/about/about';
import Works       from 'components/03_pages/works/works';
import { 
  WPython,
  WC,
  WJava,
  WHtml,
  WBackend,
 } from 'components/03_pages/works/work';
import Test        from 'components/03_pages/test/test';
import { AnimatePresence } from 'framer-motion';

const AnimatedRoutes = () => {
  const location = useLocation()
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path='/'            element={<Home/>}/>
        <Route path="/about"       element={<About />} />
        <Route path="/works"       element={<Layout />} >
          <Route index element={<Works />} />
          <Route path={LangList[0]} element={<WPython />} />
          <Route path={LangList[1]} element={<WC />} />
          <Route path={LangList[2]} element={<WJava />} />
          <Route path={LangList[3]} element={<WHtml />} />
          <Route path={LangList[4]} element={<WBackend />} />
        </Route>
        <Route path="/floatUpTest" element={<FloatUpTest />} />
        <Route path="/test"        element={<Test />} />
        <Route path="*"            element={<ErrorPage /> } />
      </Routes>
    </AnimatePresence>
)
}

export default AnimatedRoutes
