import { RefObject, createRef, useEffect, useRef, useState } from "react";
import MotionDiv from 'components/00_atoms/MotionDIv/MotionDIv';
import './test.scss'
import FloatCard from "components/00_atoms/floatCard";

const Test = () => {
  useEffect(() => {
    window.scrollTo({
      top: 1,
    });
    window.scrollTo({
      top: 0,
    });
  }, [])

  return (
    <MotionDiv className='test-wrapper'>
      <div style={{height: '100vh', width: '100vw',}}>
        <h1>CORE</h1>
      </div>
      <div className="header">
        <FloatCard className={"floatCard-test"}>
          <div style={{
            height: 500, 
            width: 500,
            fontSize: '50px',
            textAlign: 'center',
            textShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)'
          }}>header test</div>
        </FloatCard>
        <FloatCard className={"floatCard-test"}>
          <Card></Card>
        </FloatCard>
      </div>
      <div className="header">
        <FloatCard className={"floatCard-test"}>
          <div style={{
            height: 500, 
            width: 500,
            fontSize: '50px',
            textAlign: 'center',
            textShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)'
          }}>header test</div>
        </FloatCard>
        <FloatCard className={"floatCard-test"}>
          <Card></Card>
        </FloatCard>
      </div>
      <div className="header">
        <FloatCard className={"floatCard-test"}>
          <div style={{
            height: 500, 
            width: 500,
            fontSize: '50px',
            textAlign: 'center',
            textShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)'
          }}>header test</div>
        </FloatCard>
        <FloatCard className={"floatCard-test"}>
          <Card></Card>
        </FloatCard>
      </div>
      <div className="header">
        <FloatCard className={"floatCard-test"}>
          <div style={{
            height: 500, 
            width: 500,
            fontSize: '50px',
            textAlign: 'center',
            textShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)'
          }}>header test</div>
        </FloatCard>
        <FloatCard className={"floatCard-test"}>
          <Card></Card>
        </FloatCard>
      </div>
      <div className="header">
        <FloatCard className={"floatCard-test"}>
          <div style={{
            height: 500, 
            width: 500,
            fontSize: '50px',
            textAlign: 'center',
            textShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)'
          }}>header test</div>
        </FloatCard>
        <FloatCard className={"floatCard-test"}>
          <Card></Card>
        </FloatCard>
      </div>
    </MotionDiv>
  )
}

// test component

const Card = () => {
  return (
    <div style={{
      height: 500,
      width : 500,
      background: 'rgba(0, 0, 0, 0.2)',
      boxShadow: '30px 30px 30px rgba(0, 0, 0, 0.5)',
      borderRadius: '50% 20% / 10% 40%',
    }}>

    </div>
  )
}

export default Test
