import React, {useState} from 'react'
import { NavLink, useNavigate} from 'react-router-dom'
import I from 'static/img/i'
import { sizes } from 'static/ts/constants'
import HamburgerToggle from 'components/00_atoms/hamburgerToggle/hamburgerToggle'
import Popup from 'components/00_atoms/popup/popup'
import './header.scss'

const Header = () => {
  const [isDisp, setIsDisp]   = useState(true)
  const [isShown, setIsShown] = useState(false)
  const navigate = useNavigate()

  const popupHandler = (e : React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back' || id === 'header-item') {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  const onClickLink = (e : React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    let path = e.currentTarget.id
    setIsShown(false)
    setTimeout(() => {
      navigate('/' + path)
    }, 0.5 * 1000)
  }

  return (
    <header 
      style={{
        height: sizes.header.height
      }}
      className={'header-wrapper'}
    >
      <a href='/' className='icon'>
        <I/><h1 className='icon'>CLOSM</h1>
      </a>
      <div className='sub'>
        <HamburgerToggle
          onClick = {popupHandler}
          isOpen  = {isShown}
        />
        {
          <Popup
            isShown      = {isShown}
            popupHandler = {popupHandler}
          >
            <div className={'headerPopup-wrapper'}>
              {/* <NavLink to={'about'}>ABOUT</NavLink>
              <NavLink to={'works'}>WORKS</NavLink>
              <NavLink to={'floatUpTest'}>floatUpTest</NavLink>
              <NavLink to={'test'}>TEST</NavLink>
              <NavLink to={'/'}>HOME</NavLink> */}
              <a onClick={onClickLink} id={'about'}>ABOUT</a>
              <a onClick={onClickLink} id={'works'}>WORKS</a>
              <a onClick={onClickLink} id={'floatUpTest'}>FLOAT UP TEST</a>
              <a onClick={onClickLink} id={'test'}>TEST</a>
            </div>
          </Popup>
        }
      </div>
    </header>
  )
}

export default Header
