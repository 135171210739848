import React, { useRef, createRef, RefObject, useEffect } from 'react';
import { useIntersectionObserver } from 'static/ts/hooks';
import MotionDiv from 'components/00_atoms/MotionDIv/MotionDIv';
import ScrollText from 'components/00_atoms/scrollText';
import './about.scss'

const About = () => {
  const ListRefs = useRef<RefObject<HTMLDivElement>[]>(    
    Array(10).fill(null).map(() => createRef())
  );

  const showElements = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove('INVISIBLE')
        entry.target.classList.add('VISIBLE')
        return;
      }
      if (entry.boundingClientRect.top > 0) {
        entry.target.classList.remove('VISIBLE')
        entry.target.classList.add('INVISIBLE')
      } else {
        // entry.target.classList.remove('VISIBLE') // 画面より上に表示中
      }
    })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    });
  }, [])

  useIntersectionObserver(ListRefs.current, showElements)

  return (
    <MotionDiv className='about-wrapper'>
      <ScrollText
        className={'part2'}
        fontSizeRatio={2}
        sRGB={{
          red: 150,
          blue: 150,
          green :150, 
        }}
      >
        <div className='v-p-trans'>
          <h1>What is&nbsp;</h1>
          <h1>CLOSM ?</h1>
        </div>
      </ScrollText>
      <div 
        className='v-p-trans header'
        ref={ListRefs.current[0]}
      >
        <h2>直感的・論理的な部分を</h2>
        <h2>繋ぎ止めます</h2>
      </div>
      <div 
        className='v-p-trans header'
        ref={ListRefs.current[1]}
      >
        <h2>CLOSMは"活動体"です</h2>
      </div>
      <div 
        className='v-p-trans header'
        ref={ListRefs.current[2]}
      >
        <h2>ジャンルに囚われない</h2>
        <h2>プロダクトを生み出します</h2>
      </div>
      <p ref={ListRefs.current[3]}>
        CLOSM is an active body that connects <br />
        intuitive and rational senses.<br />
        Through a variety of experiences.<br />
      </p>
      <p ref={ListRefs.current[4]}>
        emotion and logic, <br />
        imagination and a sense of reality, <br />
        and art and science, <br />
        through a variety of experiences.<br />
      </p>
      <p ref={ListRefs.current[5]}>
        It is a fusion of passionate experience <br />
        that appeals to the audience's hearts and minds, <br />
        and analytical design, <br />
        which stimulates the senses of the experience.<br />
      </p>
      <p ref={ListRefs.current[6]}>
        CLOSM's activities allow us to connect the intuitive <br />
        and the rational senses in order to<br />
        help people make new discoveries and express themselves.<br />        
      </p>
    </MotionDiv>
  )
}

export default About
